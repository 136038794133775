#overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
  z-index: 1000;

  .alert {
    z-index: 6001;
  }

  .message {
    z-index: 4001;
  }
  .modal {
    z-index: 3001;
  }
  .dialog {
    z-index: 2001;
  }
  .drawer {
    z-index: 1001;
  }
}

#overlay-notifications {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 1001;

  .notification {
    z-index: 5001;
  }
}
