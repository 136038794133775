@use "@scss/global.scss" as *;

.col {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.grid {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.fixed-100-100 {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
}

.disabled {
  pointer-events: none;
}

.d-b {
  display: block;
}

.d-n {
  display: none;
}

.click-insensitive {
  -webkit-tap-highlight-color: transparent;
}

.ts-1 {
  text-shadow: $ts-1;
}
.ts-2 {
  text-shadow: $ts-2;
}
.ts-3 {
  text-shadow: $ts-3;
}

.text-center {
  text-align: center;
}

.t-c {
  text-align: center;
}

.text-right {
  text-align: right;
}

.ellipsis {
  word-wrap: break-word;
  @include ellipsis-after-lines(1);
}

.overflow-n {
  overflow: hidden;
}

.overflow-x-n {
  overflow-x: hidden;
}

.overflow-y-n {
  overflow-y: hidden;
}

.pointer {
  cursor: pointer;
}

.required-text {
  &:after {
    content: "*";
    color: var(--danger);
  }
}

.none-events {
  pointer-events: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  &::first-letter {
    text-transform: capitalize;
  }
}

.capitalize-each {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.relative {
  position: relative;
}

.relative-center {
  @include relative-center;
}

.relative-center-x {
  @include relative-center-x;
}

.relative-center-y {
  @include relative-center-y;
}

.absolute {
  position: absolute;
}

.absolute-center {
  @include absolute-center;
}

.absolute-center-x {
  @include absolute-center-x;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.animation-pulse {
  animation: pulsing-scale 0.6s infinite;
  animation-timing-function: linear;
}

.thin {
  font-weight: $thin !important;
}
.light {
  font-weight: $light !important;
}
.regular {
  font-weight: $regular !important;
}
.medium {
  font-weight: $medium !important;
}
.semi-bold {
  font-weight: $semi-bold !important;
}
.bold {
  font-weight: $bold !important;
}
.extra-bold {
  font-weight: $extra-bold !important;
}
.black-bold {
  font-weight: $black-bold !important;
}

.op-0 {
  opacity: 0;
}
.op-01 {
  opacity: 0.1;
}
.op-02 {
  opacity: 0.2;
}
.op-03 {
  opacity: 0.3;
}
.op-04 {
  opacity: 0.4;
}
.op-05 {
  opacity: 0.5;
}
.op-06 {
  opacity: 0.6;
}
.op-07 {
  opacity: 0.7;
}
.op-08 {
  opacity: 0.8;
}
.op-09 {
  opacity: 0.9;
}
.op-10 {
  opacity: 1;
}

%roundness-1 {
  border-radius: $roundness-1;
}
%roundness-2 {
  border-radius: $roundness-2;
}
%roundness-3 {
  border-radius: $roundness-3;
}
%roundness-4 {
  border-radius: $roundness-4;
}
%roundness-5 {
  border-radius: $roundness-5;
}
%roundness-6 {
  border-radius: $roundness-6;
}
%roundness-7 {
  border-radius: $roundness-7;
}
%roundness-8 {
  border-radius: $roundness-8;
}
%roundness-9 {
  border-radius: $roundness-9;
}
%roundness-10 {
  border-radius: $roundness-10;
}
%roundness-100 {
  border-radius: $roundness-100;
}

.roundness-1 {
  @extend %roundness-1;
}
.roundness-2 {
  @extend %roundness-2;
}
.roundness-3 {
  @extend %roundness-3;
}
.roundness-4 {
  @extend %roundness-4;
}
.roundness-5 {
  @extend %roundness-5;
}
.roundness-6 {
  @extend %roundness-6;
}
.roundness-7 {
  @extend %roundness-7;
}
.roundness-8 {
  @extend %roundness-8;
}
.roundness-9 {
  @extend %roundness-9;
}
.roundness-10 {
  @extend %roundness-10;
}
.roundness-100 {
  @extend %roundness-100;
}

.white-0 {
  color: $white-0;
}

.shade-1 {
  color: $shade-1;
}

.shade-2 {
  color: $shade-2;
}

.shade-3 {
  color: $shade-3;
}

.shade-4 {
  color: $shade-4;
}

.shade-5 {
  color: $shade-5;
}

.shade-6 {
  color: $shade-6;
}

.shade-7 {
  color: $shade-7;
}

.shade-8 {
  color: $shade-8;
}

.shade-9 {
  color: $shade-9;
}

.shade-10 {
  color: $shade-10;
}

.shade-11 {
  color: $shade-11;
}

.shade-12 {
  color: $shade-12;
}

.shade-13 {
  color: $shade-13;
}

.black-0 {
  color: $black-0;
}

.primary-100 {
  color: var(--primary-100);
}
.primary-200 {
  color: var(--primary-200);
}
.primary-300 {
  color: var(--primary-300);
}
.primary-400 {
  color: var(--primary-400);
}
.primary-500 {
  color: var(--primary-500);
}
.primary-600 {
  color: var(--primary-600);
}
.primary-700 {
  color: var(--primary-700);
}
.primary-800 {
  color: var(--primary-800);
}
.primary-900 {
  color: var(--primary-900);
}
.primary-1000 {
  color: var(--primary-1000);
}
.primary-1100 {
  color: var(--primary-1100);
}
.primary-1200 {
  color: var(--primary-1200);
}
.primary-1300 {
  color: var(--primary-1300);
}
.primary-1400 {
  color: var(--primary-1400);
}

.bg-primary-100 {
  background: var(--primary-100);
}
.bg-primary-200 {
  background: var(--primary-200);
}
.bg-primary-300 {
  background: var(--primary-300);
}
.bg-primary-400 {
  background: var(--primary-400);
}
.bg-primary-500 {
  background: var(--primary-500);
}
.bg-primary-600 {
  background: var(--primary-600);
}
.bg-primary-700 {
  background: var(--primary-700);
}
.bg-primary-800 {
  background: var(--primary-800);
}
.bg-primary-900 {
  background: var(--primary-900);
}
.bg-primary-1000 {
  background: var(--primary-1000);
}
.bg-primary-1100 {
  background: var(--primary-1100);
}
.bg-primary-1200 {
  background: var(--primary-1200);
}
.bg-primary-1300 {
  background: var(--primary-1300);
}
.bg-primary-1400 {
  background: var(--primary-1400);
}

.secondary-100 {
  color: var(--secondary-100);
}
.secondary-200 {
  color: var(--secondary-200);
}
.secondary-300 {
  color: var(--secondary-300);
}
.secondary-400 {
  color: var(--secondary-400);
}
.secondary-500 {
  color: var(--secondary-500);
}
.secondary-600 {
  color: var(--secondary-600);
}
.secondary-700 {
  color: var(--secondary-700);
}
.secondary-800 {
  color: var(--secondary-800);
}
.secondary-900 {
  color: var(--secondary-900);
}
.secondary-1000 {
  color: var(--secondary-1000);
}
.secondary-1100 {
  color: var(--secondary-1100);
}
.secondary-1200 {
  color: var(--secondary-1200);
}
.secondary-1300 {
  color: var(--secondary-1300);
}
.secondary-1400 {
  color: var(--secondary-1400);
}

.bg-secondary-100 {
  background: var(--secondary-100);
}
.bg-secondary-200 {
  background: var(--secondary-200);
}
.bg-secondary-300 {
  background: var(--secondary-300);
}
.bg-secondary-400 {
  background: var(--secondary-400);
}
.bg-secondary-500 {
  background: var(--secondary-500);
}
.bg-secondary-600 {
  background: var(--secondary-600);
}
.bg-secondary-700 {
  background: var(--secondary-700);
}
.bg-secondary-800 {
  background: var(--secondary-800);
}
.bg-secondary-900 {
  background: var(--secondary-900);
}
.bg-secondary-1000 {
  background: var(--secondary-1000);
}
.bg-secondary-1100 {
  background: var(--secondary-1100);
}
.bg-secondary-1200 {
  background: var(--secondary-1200);
}
.bg-secondary-1300 {
  background: var(--secondary-1300);
}
.bg-secondary-1400 {
  background: var(--secondary-1400);
}

.primary {
  color: var(--primary);
}

.bg-primary {
  background: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.bg-secondary {
  background: var(--secondary);
}

.success {
  color: var(--success-500);
}
.bg-success {
  background: var(--success-500);
}

.success-100 {
  color: var(--success-100);
}

.bg-success-100 {
  background: var(--success-100);
}

.success-200 {
  color: var(--success-200);
}

.bg-success-200 {
  background: var(--success-200);
}

.success-300 {
  color: var(--success-300);
}

.bg-success-300 {
  background: var(--success-300);
}

.success-400 {
  color: var(--success-400);
}

.bg-success-400 {
  background: var(--success-400);
}

.success-500 {
  color: var(--success-500);
}

.bg-success-500 {
  background: var(--success-500);
}

.success-600 {
  color: var(--success-600);
}

.bg-success-600 {
  background: var(--success-600);
}

.success-700 {
  color: var(--success-700);
}

.bg-success-700 {
  background: var(--success-700);
}

.success-800 {
  color: var(--success-800);
}

.bg-success-800 {
  background: var(--success-800);
}

.success-900 {
  color: var(--success-900);
}

.bg-success-900 {
  background: var(--success-900);
}

.success-1000 {
  color: var(--success-1000);
}

.bg-success-1000 {
  background: var(--success-1000);
}

.danger {
  color: var(--danger-500);
}

.bg-danger {
  background: var(--danger-500);
}

.danger-100 {
  color: var(--danger-100);
}

.bg-danger-100 {
  background: var(--danger-100);
}

.danger-200 {
  color: var(--danger-200);
}

.bg-danger-200 {
  background: var(--danger-200);
}

.danger-300 {
  color: var(--danger-300);
}

.bg-danger-300 {
  background: var(--danger-300);
}

.danger-400 {
  color: var(--danger-400);
}

.bg-danger-400 {
  background: var(--danger-400);
}

.danger-500 {
  color: var(--danger-500);
}

.bg-danger-500 {
  background: var(--danger-500);
}

.danger-600 {
  color: var(--danger-600);
}

.bg-danger-600 {
  background: var(--danger-600);
}

.danger-700 {
  color: var(--danger-700);
}

.bg-danger-700 {
  background: var(--danger-700);
}

.danger-800 {
  color: var(--danger-800);
}

.bg-danger-800 {
  background: var(--danger-800);
}

.danger-900 {
  color: var(--danger-900);
}

.bg-danger-900 {
  background: var(--danger-900);
}

.danger-1000 {
  color: var(--danger-1000);
}

.bg-danger-1000 {
  background: var(--danger-1000);
}

.warning {
  color: var(--warning-500);
}

.bg-warning {
  background: var(--warning-500);
}

.warning-100 {
  color: var(--warning-100);
}

.bg-warning-100 {
  background: var(--warning-100);
}

.warning-200 {
  color: var(--warning-200);
}

.bg-warning-200 {
  background: var(--warning-200);
}

.warning-300 {
  color: var(--warning-300);
}

.bg-warning-300 {
  background: var(--warning-300);
}

.warning-400 {
  color: var(--warning-400);
}

.bg-warning-400 {
  background: var(--warning-400);
}

.warning-500 {
  color: var(--warning-500);
}

.bg-warning-500 {
  background: var(--warning-500);
}

.warning-600 {
  color: var(--warning-600);
}

.bg-warning-600 {
  background: var(--warning-600);
}

.warning-700 {
  color: var(--warning-700);
}

.bg-warning-700 {
  background: var(--warning-700);
}

.warning-800 {
  color: var(--warning-800);
}

.bg-warning-800 {
  background: var(--warning-800);
}

.warning-900 {
  color: var(--warning-900);
}

.bg-warning-900 {
  background: var(--warning-900);
}

.warning-1000 {
  color: var(--warning-1000);
}

.bg-warning-1000 {
  background: var(--warning-1000);
}

.bg-gradient-1 {
  background: var(--gradient-1);
}

.transparent {
  color: transparent;
}

.bg-transparent {
  background: transparent;
}

.pulse-opacity-animation {
  animation-name: pulse-opacity;
  animation-duration: 2.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
