@use "@scss/variables.scss" as *;

@mixin translate($x, $y) {
  -webkit-transform: translate3d($x, $y, 0);
  -moz-transform: translate3d($x, $y, 0);
  -ms-transform: translate3d($x, $y, 0);
  -o-transform: translate3d($x, $y, 0);
  transform: translate3d($x, $y, 0);
}
@mixin translateX($x) {
  -webkit-transform: translate3d($x, 0, 0);
  -moz-transform: translate3d($x, 0, 0);
  -ms-transform: translate3d($x, 0, 0);
  -o-transform: translate3d($x, 0, 0);
  transform: translate3d($x, 0, 0);
}
@mixin translateY($y) {
  -webkit-transform: translate3d(0, $y, 0);
  -moz-transform: translate3d(0, $y, 0);
  -ms-transform: translate3d(0, $y, 0);
  -o-transform: translate3d(0, $y, 0);
  transform: translate3d(0, $y, 0);
}

@mixin height-max-content {
  -webkit-height: max-content;
  -moz-height: max-content;
  -ms-height: max-content;
  -o-height: max-content;
  height: max-content;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row($direction) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: $direction;
}

@mixin flex-column($v-direction, $h-direction: center) {
  display: flex;
  flex-direction: column;
  align-items: $v-direction;
  justify-content: $h-direction;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@mixin ellipsis-after-lines($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin capitalize() {
  & *::first-letter {
    text-transform: uppercase;
  }
}

@mixin absolute-center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-center-x() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin relative-center() {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin relative-center-x() {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@mixin relative-center-y() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin bg-blur($value) {
  -webkit-backdrop-filter: blur($value);
  -moz-backdrop-filter: blur($value);
  -ms-backdrop-filter: blur($value);
  -o-backdrop-filter: blur($value);
  backdrop-filter: blur($value);
}
@mixin blur($value) {
  -webkit-filter: blur($value);
  -moz-filter: blur($value);
  -ms-filter: blur($value);
  -o-filter: blur($value);
  filter: blur($value);
}

@mixin user-select($user-select) {
  -webkit-user-select: $user-select;
  -moz-user-select: $user-select;
  -o-user-select: $user-select;
  -ms-user-select: $user-select;
  user-select: $user-select;
}

@mixin mq-min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin mq-max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin text($fs, $fw, $ls, $lh) {
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
  letter-spacing: $ls;
}
