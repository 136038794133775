//COLORS
$white-0: #ffffff;
$shade-1: #f2f2f2;
$shade-2: #e6e6e6;
$shade-3: #d9d9d9;
$shade-4: #cccccc;
$shade-5: #b3b3b3;
$shade-6: #999999;
$shade-7: #808080;
$shade-8: #666666;
$shade-9: #4d4d4d;
$shade-10: #333333;
$shade-11: #262626;
$shade-12: #1a1a1a;
$shade-13: #0d0d0d;
$black-0: #000000;

$primary: var(--primary);
$secondary: var(--secondary);
//COLORS

// BREAKPOINTS
$xs: 0px;
$sm: 360px;
$sm-l: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
//BREAKPOINTS

//ROUNDNESS
$roundness-1: 2px;
$roundness-2: 4px;
$roundness-3: 6px;
$roundness-4: 8px;
$roundness-5: 10px;
$roundness-6: 12px;
$roundness-7: 16px;
$roundness-8: 20px;
$roundness-9: 24px;
$roundness-10: 32px;
$roundness-100: 100vw;
//ROUNDNESS

//SIZES
$size-dx: 8px;

$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;
$size-11: $size-dx * 11;
$size-12: $size-dx * 12;
$size-13: $size-dx * 13;
$size-14: $size-dx * 14;
$size-15: $size-dx * 15;
$size-16: $size-dx * 16;
$size-17: $size-dx * 17;
$size-18: $size-dx * 18;
$size-19: $size-dx * 19;
$size-20: $size-dx * 20;
//SIZES

// FONT WEIGHTS
$thin: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black-bold: 900;
// FONT WEIGHTS

//FONT-SIZES
$fs-d1: 71px;
$fs-1: 61px;
$fs-2: 52px;
$fs-3: 45px;
$fs-4: 39px;
$fs-5: 34px;
$fs-6: 29px;
$fs-7: 25px;
$fs-8: 22px;
$fs-9: 19px;
$fs-10: 16px;
$fs-11: 14px;
$fs-12: 12px;
//FONT-SIZES

//LETTER-SPACING
$ls-1: 0.05em;
$ls-2: 0em;
$ls-3: -0.01em;
$ls-4: -0.02em;
$ls-6: -0.03em;
$ls-7: -0.04em;
$ls-8: -0.05em;

//LETTER-SPACING

//LINE-HEIGHT
$lh-1: 100%;
$lh-2: 115%;
$lh-3: 120%;
$lh-4: 130%;
$lh-5: 140%;
$lh-6: 150%;
$lh-7: 160%;
//LINE-HEIGHT

$ts-1: 0px 1px 2px rgba(0, 0, 0, 0.35);
$ts-2: 0px 1px 2px rgba(255, 255, 255, 0.35);
$ts-3: 0px 2px 1px rgba(255, 255, 255, 0.35);

$bs-1: 0px 5px 80px 0px rgba(0, 0, 0, 0.37), 0px 1.926px 25.481px 0px rgba(0, 0, 0, 0.22),
  0px 0.407px 6.519px 0px rgba(0, 0, 0, 0.15);
$bs-2: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
$bs-3: inset 0px 0px 8px 0px #000;
$bs-4: 0px 2px 20px 0px rgba(0, 0, 0, 0.4);
$bs-5: 0px 1px 2px 0px rgba(0, 0, 0, 0.35);
$bs-6: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
