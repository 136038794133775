@use "@scss/variables.scss" as *;
@use "@scss/mixins.scss" as *;
@use "@scss/typography-mixins.scss" as *;

//TYPOGRAPHY

@font-face {
  font-family: "PongGame";
  src: url("../../../../public/fonts/Pong-Game.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap");

.maven {
  font-family: "Maven Pro", sans-serif;
}

.d1 {
  @include d1();
}

h1,
.h1 {
  @include h1();
}

h2,
.h2 {
  @include h2();

  @include mq-max($md) {
    @include h6;
  }
}

h3,
.h3 {
  @include h3();
}

h4,
.h4 {
  @include h4();
}

h5,
.h5 {
  @include h5();

  @include mq-max($md) {
    @include h6();
  }
}

h6,
.h6 {
  @include h6();

  @include mq-max($md) {
    @include b2;
  }
}

.b1 {
  @include b1;
}
.b2 {
  @include b2;
}
.b3 {
  @include b3;
}
.b4 {
  @include b4;
}

.p1 {
  @include p1();
}

.p2 {
  @include p2();
}

h1.maven,
.h1.maven {
  @include h1-maven();
}
h2.maven,
.h2.maven {
  @include h2-maven();
}
h3.maven,
.h3.maven {
  @include h3-maven();
}
h4.maven,
.h4.maven {
  @include h4-maven();
}
h5.maven,
.h5.maven {
  @include h5-maven();
}
h6.maven,
.h6.maven {
  @include h6-maven();

  @include mq-max($md) {
    @include b1-maven();
  }
}
.maven.b1 {
  @include b1-maven();

  @include mq-max($md) {
    @include p1-maven();
  }
}
.maven.b1-bold {
  @include b1-bold-maven();
}
.maven.b2 {
  @include b2-maven();
}
.maven.b2-bold {
  @include b2-bold-maven();
}
.maven.p1 {
  @include p1-maven();
}

//TYPOGRAPHY
