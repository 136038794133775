@use "@scss/mixins.scss" as *;
@use "@scss/variables.scss" as *;

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  box-sizing: border-box;
}

button,
a {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

img,
a {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

svg {
  display: block;
  &,
  * {
    stroke: var(--secondary);
    // fill: var(--secondary);
  }
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  color: var(--secondary);
  // overscroll-behavior: contain;
}

body {
  position: relative;
  overflow: hidden;
  // overscroll-behavior: contain;
}

section {
  @include height-max-content;
  width: 100%;
}

html {
  height: 100svh;
  height: 100vh;

  touch-action: manipulation;
  -ms-touch-action: manipulation;
}

body,
#root {
  height: inherit;
}

hr {
  margin: 0px;
  padding: 0px;
}

a {
  color: unset;
  text-decoration: none;
  // height: 100%;
  @include flex-center();
  min-height: 40px;
  min-width: 40px;

  > * {
    height: 100%;
  }

  &.default {
    color: unset;
    text-decoration: underline;
    display: block;
    min-height: unset;
    min-width: unset;
  }
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
}

input {
  border: none;
}
